import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { LoginPage } from "./pages/login";
import Home from "./pages/home";
import { ProtectedRoute } from "./components/protectedRoute";
import { Secret } from "./pages/secret";
import { AuthProvider } from "./hooks/useAuth";
import ComputeFinalPlaylist from "./pages/computingFinalPlay";
import DeFinalPlaylist from "./pages/finalPlaylist";
import VideoPage from "./pages/videoPage";
import HintPage from "./pages/hint";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/cadeau"
          element={
            <ProtectedRoute>
              <Secret />
            </ProtectedRoute>
          }
        />
        <Route
          path="/finalplaylist"
          element={
            <ProtectedRoute>
              <DeFinalPlaylist />
            </ProtectedRoute>
          }
        />
        <Route
          path="/videoPage"
          element={
            <ProtectedRoute>
              <VideoPage />
            </ProtectedRoute>
          }
        />
        <Route path="/hintPage" element={<HintPage />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;

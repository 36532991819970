import { useAuth } from "../hooks/useAuth";

export const Secret = () => {
  const { logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        gap: "20%",
      }}
    >
      <h1 style={{ color: "white" }}>Déconexion Auto</h1>
      <button style={{ fontSize: "30px" }} onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
};
